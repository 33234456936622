<template>
  <v-app>
    <tev-navbar backTo="/login" title="" />
    <v-content>
      <v-layout row wrap text-xs-center pb-5 v-if="isLoading">
        <v-flex>
          <v-progress-circular :size="50" color="primary" indeterminate />
        </v-flex>       
      </v-layout>  

      <v-layout row wrap pt-4 v-if="!isLoading">
        <v-flex xs12>
          <v-form ref="form" class="form-sm">
            <v-container>
              <transition name="fade">
                <template v-if="emailSent">
                    <v-layout row wrap>
                      <v-flex xs12 sm12 text-xs-center>
                        <div class="email-sent-circle"></div>
                        <h4 class="email-sent-label">Email Sent!</h4>
                        <h3 class="mb-4">Check your email for a link to reset your password.</h3>
                        <p class="text-muted mb-5">If it doesn’t appear within a few minutes, check your spam folder.</p>
                        <v-btn depressed large color="primary" to="/login">Return to LogIn</v-btn>
                      </v-flex>
                    </v-layout>
                </template>
              </transition>
              <template v-if="!emailSent">
                <tev-pagetitle title="Forgot your password?" subtitle="Enter your email address and we will send you a link to reset your password." />
                <v-layout row wrap>
                  <v-flex xs12 sm12>
                    <v-text-field xs12 v-model="email" :rules="rules.email" label="Email" required box :autofocus="$isDesktop()"></v-text-field>
                  </v-flex>
                  <v-flex xs12 sm12 text-xs-center>
                    <v-btn type="submit" color="primary" large depressed @click="sendPassword">
                      Send
                    </v-btn>
                    <v-btn flat large color="primary" to="/login">Cancel</v-btn>
                  </v-flex>
                </v-layout>
              </template>
            </v-container>
          </v-form>
          <v-snackbar v-model="snackbar" :bottom="true" :left="true">
            {{ snackbarText }}
            <v-btn color="red" flat @click="snackbar = false">
              Close
            </v-btn>
          </v-snackbar>
        </v-flex>
      </v-layout>
    </v-content>
  </v-app>
</template>
<script>
import {
  mapGetters
} from 'vuex'
import Validator from '../libs/validator'

export default {
  data() {
    const validator = new Validator();
    return {
      isLoading: false,
      email: null,
      emailSent: false,

      snackbar: false,
      snackbarText: '',

      rules: {
        email: [
          validator.rules.required("You must complete the required fields."),
          validator.rules.email("Invalid email."),
        ]
      }
    }
  },
  computed: {
    ...mapGetters({
      currentUser: 'currentUser'
    })
  },
  methods: {
    alertError(message) {
      this.$events.$emit('showSnackbar', {
        message: message,
        color: 'red'
      });
    },
    sendPassword() {
      const _this = this;
      if(!this.$refs.form.validate()) {
        return;
      }

      this.isLoading = true;

      this.$http.post('/manager/reset-password/init', this.email)
        .then(() => {
          _this.isLoading = false;       
          _this.emailSent = true;          
        })
        .catch(e => {
          // eslint-disable-next-line
          console.error(e)
          _this.isLoading = false;       
          _this.alertError(e.response.data.title || 'An error occurred while processing your request.')
        })
    }
  }
}
</script>

<style>

</style>
