var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "v-app",
    [
      _c("tev-navbar", { attrs: { backTo: "/login", title: "" } }),
      _c(
        "v-content",
        [
          _vm.isLoading
            ? _c(
                "v-layout",
                {
                  attrs: {
                    row: "",
                    wrap: "",
                    "text-xs-center": "",
                    "pb-5": "",
                  },
                },
                [
                  _c(
                    "v-flex",
                    [
                      _c("v-progress-circular", {
                        attrs: {
                          size: 50,
                          color: "primary",
                          indeterminate: "",
                        },
                      }),
                    ],
                    1
                  ),
                ],
                1
              )
            : _vm._e(),
          !_vm.isLoading
            ? _c(
                "v-layout",
                { attrs: { row: "", wrap: "", "pt-4": "" } },
                [
                  _c(
                    "v-flex",
                    { attrs: { xs12: "" } },
                    [
                      _c(
                        "v-form",
                        { ref: "form", staticClass: "form-sm" },
                        [
                          _c(
                            "v-container",
                            [
                              _c(
                                "transition",
                                { attrs: { name: "fade" } },
                                [
                                  _vm.emailSent
                                    ? [
                                        _c(
                                          "v-layout",
                                          { attrs: { row: "", wrap: "" } },
                                          [
                                            _c(
                                              "v-flex",
                                              {
                                                attrs: {
                                                  xs12: "",
                                                  sm12: "",
                                                  "text-xs-center": "",
                                                },
                                              },
                                              [
                                                _c("div", {
                                                  staticClass:
                                                    "email-sent-circle",
                                                }),
                                                _c(
                                                  "h4",
                                                  {
                                                    staticClass:
                                                      "email-sent-label",
                                                  },
                                                  [_vm._v("Email Sent!")]
                                                ),
                                                _c(
                                                  "h3",
                                                  { staticClass: "mb-4" },
                                                  [
                                                    _vm._v(
                                                      "Check your email for a link to reset your password."
                                                    ),
                                                  ]
                                                ),
                                                _c(
                                                  "p",
                                                  {
                                                    staticClass:
                                                      "text-muted mb-5",
                                                  },
                                                  [
                                                    _vm._v(
                                                      "If it doesn’t appear within a few minutes, check your spam folder."
                                                    ),
                                                  ]
                                                ),
                                                _c(
                                                  "v-btn",
                                                  {
                                                    attrs: {
                                                      depressed: "",
                                                      large: "",
                                                      color: "primary",
                                                      to: "/login",
                                                    },
                                                  },
                                                  [_vm._v("Return to LogIn")]
                                                ),
                                              ],
                                              1
                                            ),
                                          ],
                                          1
                                        ),
                                      ]
                                    : _vm._e(),
                                ],
                                2
                              ),
                              !_vm.emailSent
                                ? [
                                    _c("tev-pagetitle", {
                                      attrs: {
                                        title: "Forgot your password?",
                                        subtitle:
                                          "Enter your email address and we will send you a link to reset your password.",
                                      },
                                    }),
                                    _c(
                                      "v-layout",
                                      { attrs: { row: "", wrap: "" } },
                                      [
                                        _c(
                                          "v-flex",
                                          { attrs: { xs12: "", sm12: "" } },
                                          [
                                            _c("v-text-field", {
                                              attrs: {
                                                xs12: "",
                                                rules: _vm.rules.email,
                                                label: "Email",
                                                required: "",
                                                box: "",
                                                autofocus: _vm.$isDesktop(),
                                              },
                                              model: {
                                                value: _vm.email,
                                                callback: function ($$v) {
                                                  _vm.email = $$v
                                                },
                                                expression: "email",
                                              },
                                            }),
                                          ],
                                          1
                                        ),
                                        _c(
                                          "v-flex",
                                          {
                                            attrs: {
                                              xs12: "",
                                              sm12: "",
                                              "text-xs-center": "",
                                            },
                                          },
                                          [
                                            _c(
                                              "v-btn",
                                              {
                                                attrs: {
                                                  type: "submit",
                                                  color: "primary",
                                                  large: "",
                                                  depressed: "",
                                                },
                                                on: { click: _vm.sendPassword },
                                              },
                                              [
                                                _vm._v(
                                                  "\n                    Send\n                  "
                                                ),
                                              ]
                                            ),
                                            _c(
                                              "v-btn",
                                              {
                                                attrs: {
                                                  flat: "",
                                                  large: "",
                                                  color: "primary",
                                                  to: "/login",
                                                },
                                              },
                                              [_vm._v("Cancel")]
                                            ),
                                          ],
                                          1
                                        ),
                                      ],
                                      1
                                    ),
                                  ]
                                : _vm._e(),
                            ],
                            2
                          ),
                        ],
                        1
                      ),
                      _c(
                        "v-snackbar",
                        {
                          attrs: { bottom: true, left: true },
                          model: {
                            value: _vm.snackbar,
                            callback: function ($$v) {
                              _vm.snackbar = $$v
                            },
                            expression: "snackbar",
                          },
                        },
                        [
                          _vm._v(
                            "\n          " +
                              _vm._s(_vm.snackbarText) +
                              "\n          "
                          ),
                          _c(
                            "v-btn",
                            {
                              attrs: { color: "red", flat: "" },
                              on: {
                                click: function ($event) {
                                  _vm.snackbar = false
                                },
                              },
                            },
                            [_vm._v("\n            Close\n          ")]
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ],
                1
              )
            : _vm._e(),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }